import dayjs from "dayjs";
import router from "./router";
import store from "./store";

export default {
  data: function () {
    return {
      APPNAME: "Lotlink POS",
      APPVERSION: "1.0.0",
      KEYPRINTERMAC: "PRINTER_MAC",
      KEYPRINTERMODE: "PRINTER_MODE",
      KEYPRINTERMODEL: "PRINTER_MODEL",
      ANIMALS: [
        {
          number: 1,
          key: "smallfish",
          name: ["Small Fish", "ປານ້ອຍ"],
          numbers: ["01", "41", "81"],
        },
        {
          number: 2,
          key: "snail",
          name: ["Snail", "ຫອຍ"],
          numbers: ["02", "42", "82"],
        },
        {
          number: 3,
          key: "goose",
          name: ["Goose", "ຫ່ານ"],
          numbers: ["03", "43", "83"],
        },
        {
          number: 4,
          key: "peacock",
          name: ["Peacock", "ນົກຍຸງ"],
          numbers: ["04", "44", "84"],
        },
        {
          number: 5,
          key: "lion",
          name: ["Lion", "ສິງ"],
          numbers: ["05", "45", "85"],
        },
        {
          number: 6,
          key: "tiger",
          name: ["Tiger", "ເສືອ"],
          numbers: ["06", "46", "86"],
        },
        {
          number: 7,
          key: "pig",
          name: ["Pig", "ໝູ"],
          numbers: ["07", "47", "87"],
        },
        {
          number: 8,
          key: "rabbit",
          name: ["Rabbit", "ກະຕ່າຍ"],
          numbers: ["08", "48", "88"],
        },
        {
          number: 9,
          key: "buffalo",
          name: ["Buffalo", "ຄວາຍ"],
          numbers: ["09", "49", "89"],
        },
        {
          number: 10,
          key: "otter",
          name: ["Otter", "ນາກນ້ຳ"],
          numbers: ["10", "50", "90"],
        },
        {
          number: 11,
          key: "dog",
          name: ["Dog", "ໝາ"],
          numbers: ["11", "51", "91"],
        },
        {
          number: 12,
          key: "horse",
          name: ["Horse", "ມ້າ"],
          numbers: ["12", "52", "92"],
        },
        {
          number: 13,
          key: "elephant",
          name: ["Elephant", "ຊ້າງ"],
          numbers: ["13", "53", "93"],
        },
        {
          number: 14,
          key: "cat",
          name: ["Cat", "ແມວ"],
          numbers: ["14", "54", "94"],
        },
        {
          number: 15,
          key: "rat",
          name: ["Rat", "ໜູ"],
          numbers: ["15", "55", "95"],
        },
        {
          number: 16,
          key: "bee",
          name: ["Bee", "ເຜິ້ງ"],
          numbers: ["16", "56", "96"],
        },
        {
          number: 17,
          key: "egret",
          name: ["Egret", "ນົກຍາງ"],
          numbers: ["17", "57", "97"],
        },
        {
          number: 18,
          key: "bobcat",
          name: ["Bobcat", "ແມວປ່າ"],
          numbers: ["18", "58", "98"],
        },
        {
          number: 19,
          key: "butterfly",
          name: ["Butterfly", "ກະເບື້ອ"],
          numbers: ["19", "59", "99"],
        },
        {
          number: 20,
          key: "centipede",
          name: ["Centipede", "ຂີ້ເຂັບ"],
          numbers: ["20", "60", "00"],
        },
        {
          number: 21,
          key: "swallow",
          name: ["Swallow", "ນົກແອ່ນ"],
          numbers: ["21", "61"],
        },
        {
          number: 22,
          key: "pigeon",
          name: ["Pigeon", "ນົກກາງແກ"],
          numbers: ["22", "62"],
        },
        {
          number: 23,
          key: "monkey",
          name: ["Monkey", "ລິງ"],
          numbers: ["23", "63"],
        },
        {
          number: 24,
          key: "frog",
          name: ["Frog", "ກົບ"],
          numbers: ["24", "64"],
        },
        {
          number: 25,
          key: "falcon",
          name: ["Falcon", "ແຫຼວ"],
          numbers: ["25", "65"],
        },
        {
          number: 26,
          key: "naga",
          name: ["Flying Otter", "ນາກບິນ"],
          numbers: ["26", "66"],
        },
        {
          number: 27,
          key: "turtle",
          name: ["Turtle", "ເຕົ່າ"],
          numbers: ["27", "67"],
        },
        {
          number: 28,
          key: "rooster",
          name: ["Rooster", "ໄກ່"],
          numbers: ["28", "68"],
        },
        {
          number: 29,
          key: "eel",
          name: ["Eel", "ອ່ຽນ"],
          numbers: ["29", "69"],
        },
        {
          number: 30,
          key: "bigfish",
          name: ["Big Fish", "ປາໃຫຍ່"],
          numbers: ["30", "70"],
        },
        {
          number: 31,
          key: "prawn",
          name: ["Prawn", "ກຸ້ງ"],
          numbers: ["31", "71"],
        },
        {
          number: 32,
          key: "snake",
          name: ["Snake", "ງູ"],
          numbers: ["32", "72"],
        },
        {
          number: 33,
          key: "spider",
          name: ["Spider", "ແມງມຸມ"],
          numbers: ["33", "73"],
        },
        {
          number: 34,
          key: "deer",
          name: ["Deer", "ກວາງ"],
          numbers: ["34", "74"],
        },
        {
          number: 35,
          key: "goat",
          name: ["Goat", "ແບ້"],
          numbers: ["35", "75"],
        },
        {
          number: 36,
          key: "palmcivet",
          name: ["Palm Civet", "ເຫງັນ"],
          numbers: ["36", "76"],
        },
        {
          number: 37,
          key: "pangolin",
          name: ["Pangolin", "ຫຼິ່ນ"],
          numbers: ["37", "77"],
        },
        {
          number: 38,
          key: "hedgehog",
          name: ["Hedgehog", "ເໝັ້ນ"],
          numbers: ["38", "78"],
        },
        {
          number: 39,
          key: "crab",
          name: ["Crab", "ກະປູ"],
          numbers: ["39", "79"],
        },
        {
          number: 40,
          key: "eagle",
          name: ["Eagle", "ນົກອິນຊີ"],
          numbers: ["40", "80"],
        },
      ],
      ZODIACS: [
        {
          number: 1,
          key: "rat",
          name: ["Rat", "ໜູ"],
          numbers: ["15", "55", "95"],
        },
        {
          number: 2,
          key: "buffalo",
          name: ["Buffalo", "ຄວາຍ"],
          numbers: ["09", "49", "89"],
        },
        {
          number: 3,
          key: "tiger",
          name: ["Tiger", "ເສືອ"],
          numbers: ["06", "46", "86"],
        },
        {
          number: 4,
          key: "rabbit",
          name: ["Rabbit", "ກະຕ່າຍ"],
          numbers: ["08", "48", "88"],
        },
        {
          number: 5,
          key: "naga",
          name: ["Flying Otter", "ນາກບິນ"],
          numbers: ["26", "66"],
        },
        {
          number: 6,
          key: "snake",
          name: ["Snake", "ງູ"],
          numbers: ["32", "72"],
        },
        {
          number: 7,
          key: "horse",
          name: ["Horse", "ມ້າ"],
          numbers: ["12", "52", "92"],
        },
        {
          number: 8,
          key: "goat",
          name: ["Goat", "ແບ້"],
          numbers: ["35", "75"],
        },
        {
          number: 9,
          key: "monkey",
          name: ["Monkey", "ລິງ"],
          numbers: ["23", "63"],
        },
        {
          number: 10,
          key: "rooster",
          name: ["Rooster", "ໄກ່"],
          numbers: ["28", "68"],
        },
        {
          number: 11,
          key: "dog",
          name: ["Dog", "ໝາ"],
          numbers: ["11", "51", "91"],
        },
        {
          number: 12,
          key: "pig",
          name: ["Pig", "ໝູ"],
          numbers: ["07", "47", "87"],
        },
      ],
    };
  },
  filters: {
    formatnumber(x) {
      if (!x) return 0;
      if (x) {
        return (x % 1 != 0 ? x.toFixed(2) : x)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      return x;
    },
    formatdigit(x) {
      if (!x) return "";
      return x;
    },
    datetime(x) {
      if (x) {
        return dayjs(x).format("DD/MM/YYYY HH:mm:ss");
      }
      return x;
    },
    date(x) {
      if (x) {
        return dayjs(x).format("DD/MM/YYYY");
      }
      return x;
    },
    time(x) {
      if (x) {
        return dayjs(x).format("HH:mm:ss");
      }
      return x;
    },
  },
  methods: {
    go(p) {
      router.push({ path: p });
    },
    goback() {
      router.back();
    },
    formatnumber(x) {
      if (x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      return x;
    },
    datetime(x) {
      if (x) {
        return dayjs(x).format("DD/MM/YYYY HH:mm:ss");
      }
      return x;
    },
    date(x) {
      if (x) {
        return dayjs(x).format("DD/MM/YYYY");
      }
      return x;
    },
    time(x) {
      if (x) {
        return dayjs(x).format("HH:mm:ss");
      }
      return x;
    },
    randomAlphaNumeric(length) {
      const chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let result = "";

      for (let i = 0; i < length; i++) {
        result += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return result;
    },
    randomNumber(length) {
      const chars = "0123456789";
      let result = "";
      for (let i = 0; i < length; i++) {
        result += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return result;
    },
    getanimalname(n) {
      n = "" + n;
      if (!n) {
        return "";
      }
      if (n.length < 2) {
        n = "0" + n;
      }
      if (this.ANIMALS) {
        const animal = this.ANIMALS.find((a) => {
          if (a.numbers.includes(n)) {
            return true;
          } else {
            return a.numbers.find((f) => f === n.substr(-2)) != null;
          }
        });
        return animal.name[1];
      }
      return "";
    },
    getanimalimage(n) {
      n = "" + n;
      if (!n) {
        return "";
      }
      if (n.length < 2) {
        n = "0" + n;
      }
      if (this.ANIMALS) {
        const animal = this.ANIMALS.find((a) => {
          if (a.numbers.includes(n)) {
            return true;
          } else {
            return a.numbers.find((f) => f === n.substr(-2)) != null;
          }
        });
        return "animal/animal_" + (animal ? animal.number : "") + ".png";
      }
      return "";
    },
    toast(message) {
      console.log("toast", message);
      if (message === "Duplicated reference number") {
        message = "ໄດ້ກົດພິມບິນໄປແລ້ວ ກະລຸນາກວດປະຫວັດການຂາຍ ຫຼື ເລີ່ມໃໝ່";
      }
      if (window["Android"]) {
        window["Android"].showToast(message);
      } else {
        store.set("toastmessage", message);
        setTimeout(() => {
          store.set("toastmessage", null);
        }, 5000);
      }
    },
    sumBy(items, propertyName) {
      return items.reduce((sum, item) => sum + (item[propertyName] || 0), 0);
    },
    parseError(error) {
      let err = "";
      try {
        if (error.response && error.response.data) {
          if (error.response.data instanceof String) {
            err = error.response.data ?? "";
          } else {
            err = error.response.data["message"] ?? "";
          }
        }
      } catch (e) {
        console.error("PARSEERROR", e);
      }
      if (err.includes("INSUF")) {
        err = "ເງິນບໍ່ພໍ ກະລຸນາແຈ້ງແອັດມິນ";
      }
      return !err || err == "" ? "ບໍ່ສຳເລັດ" : err;
    },
    getdeviceid() {
      try {
        return window["Android"].getFromPreferences("LOTLINK-POS-DEVICEID");
      } catch (error) {
        return process.env.VUE_APP_DEVICEID;
      }
    },
    savedeviceid(value) {
      window["Android"].saveToPreferences("LOTLINK-POS-DEVICEID", value);
    },
    isTokenExpire() {
      const lastrequesttime = store.get("lastrequesttime");
      if (lastrequesttime) {
        const shouldexpire =
          (process.env.VUE_APP_TOKENEXPIRESINMINUTE ?? 300) * 60 * 1000;
        const diff = Date.now() - lastrequesttime;
        console.log("diff", diff);
        return diff > shouldexpire;
      }
      return false;
    },
  },
  computed: {},
};
